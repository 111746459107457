.clients {
    .logos {
        // display: flex;
        justify-content: center;
        align-items: center;

        display: grid;
        grid-template-columns: 25% 25% 25%;
        grid-template-rows: 50% 50%;
        height: 50%;
        justify-items: center;

        @media screen and (max-width: 500px) {
            grid-template-columns: 33% 33%;
            grid-template-rows: calc(100% / 3) calc(100% / 3) calc(100% / 3);
        }

        & > img {
            // width: calc(100% / 7);    
            max-height: 100%;
            max-width: 100%;
            margin: 10px;
        }
    }
    
    .more {
        margin-top: 50px;
        margin-left: 150px;
        font-size: 20px;
        font-weight: 700;

        @media screen and (max-width: 850px) {
            margin-left: 0;
            width: min-content;
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (max-width: 530px) {
            font-size: 15px;
        }

        ul li {
            font-weight: normal;
            line-height: 1.6em;
            white-space: nowrap;
        }

        @media screen and (max-width: 420px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;

            ul li {
                white-space: pre-wrap;
            }
        }
    }
}