.main {
    .left {
        position: absolute;
        top: 30%;
        left: 9.5vw;
        text-align: center;

        @media screen and (max-width: 1350px) {
            left: 0;
            right: 0;
            padding: 0 20px;
        }

        @media screen and (max-height: 300px) {
            top: 20%;
        }
        
        h1.firm-name {
            font-size: 70px;
            font-weight: normal;
            margin-bottom: 0;

            @media screen and (max-width: 956px) {
                font-size: 50px;
            }

            @media screen and (max-width: 700px) {
                font-size: 40px;
            }

            @media screen and (max-width: 565px) {
                font-size: 35px;
            }
        }

        div.fast-contacts {
            display: inline-flex;
            margin-top: 75px;
            align-items: center;

            @media screen and (max-width: 956px) {
                margin-top: 50px;
            }

            @media screen and (max-height: 650px) {
                margin-top: 30px;
            }

            @media screen and (max-height: 350px) {
                margin-top: 10px;
            }

            & > div {
                font-size: 35px;

                @media screen and (max-width: 956px) {
                    font-size: 30px;
                }

                @media screen and (max-width: 700px) {
                    font-size: 25px;
                }
            }

            .separator {
                margin: 0 15px;
                font-size: 11px;
            }

            @media screen and (max-width: 634px) {
                flex-direction: column;
                & > svg {
                    display: none;
                }
            }
        }
    }

    div.links {
        position: relative;
        left: 80%;
        top: 32%;
        width: min-content;

        @media screen and (max-width: 1350px) {
            top: 4%;
            left: 75%;

            @media screen and (max-height: 400px) {
                top: 15%;
                display: flex;
                left: 0;
                right: 0;
                width: 100%;
                justify-content: center;
                & > div {
                    margin-left: 30px;
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            @media screen and (min-height: 2000px) {
                top: 70%;
                left: 0;
                margin: 0 auto;
            }
        }

        @media screen and (max-width: 956px) {
            left: 0;
            margin: 0 auto;
        }

        & > div {
            font-size: 35px;
            cursor: pointer;

            @media screen and (max-width: 956px) {
                font-size: 30px;
            }

            @media screen and (max-width: 700px) {
                font-size: 25px;
            }

            @media screen and (max-width: 565px) {
                font-size: 20px;
            }

            &:hover {
                // font-weight: bold;
                text-decoration: underline;
            }
        }

        @media screen and (max-height: 300px) {
            top: 10px;
        }
    }

    div.messengers {
        display: flex;
        align-items: center;
        bottom: 5vh;
        right: 10vw;
        position: absolute;

        @media screen and (max-width: 1350px) {
            left: 0;
            right: 0;
            place-content: center;
        }

        .buttons {
            display: flex;
        }

        svg {
            font-size: 60px;
            color: black;
        }

        img {
            width: 60px;
        }

        text {
            font-size: 30px;
        }
        
        @media screen and (max-width: 956px) {
            svg {
                font-size: 50px;
            }
    
            img {
                width: 50px;
            }
    
            text {
                font-size: 25px;
            }
        }

        @media screen and (max-width: 480px) {
            & {
                flex-direction: column;

                & > text {
                    margin-top: 10px;
                }
            }
        }
        
        & > div > *, & > text {
            margin-left: 10px;
        }
    }
}