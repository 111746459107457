.jobs {
    .header > h1{
        font-size: 50px;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 0.6em;
        
        @media screen and (max-width: 700px) {
            font-size: 40px;
        }
    }

    .body {
        display: flex;
        justify-content: space-between;
        margin: 0 10%;
        margin-top: 20vh;

        @media screen and (max-width: 1100px) {
            margin-top: 10vh;
        }

        @media screen and (max-width: 950px) {
            margin-top: 0;
            flex-direction: column;
        }

        & > div {
            flex: 1;
            margin: 0 10px;
            h1 {
                font-size: 30px;
                @media screen and (max-width: 650px) {
                    font-size: 25px;
                }
            }

            ul {
                font-size: 25px;

                @media screen and (max-width: 650px) {
                    font-size: 20px;
                }
            }
        }
    }
}