.about {
    .header > h1{
        font-size: 50px;
        margin: 0;
        margin-top: 10px;

        @media screen and (max-width: 700px) {
            font-size: 40px;
        }
    }

    p {
        white-space: pre-wrap;
        font-size: 35px;
        margin-left: 20px;
        margin-right: 20px;
    }

    div.text {
        height: calc(80% - 58px - 10px);

        @media screen and (max-width: 1400px) {
            height: calc(100% - 58px - 10px);
        }

        align-content: center;
        display: grid;

        margin-left: 20px;
        margin-right: 20px;
        
        ul {
            list-style-type: "- ";
            padding: 0;
            padding-left: 15.7px;
            font-size: 30px;

            li {
                padding-top: 10px;
            }

            @media screen and (max-width: 1400px) {
                @media screen and (max-height: 750px) {
                    font-size: 25px;
                }
                @media screen and (max-height: 650px) {
                    font-size: 22px;
                }
            }

            @media screen and (max-width: 837px) {
                @media screen and (max-height: 850px) {
                    font-size: 25px;
                }
                @media screen and (max-height: 650px) {
                    font-size: 20px;
                }
            }

            @media screen and (max-width: 650px) {
                // font-size: 25px;    
                font-size: 20px;
                // @media screen and (max-height: 850px) {
                //     font-size: 22px;
                // }
                // @media screen and (max-height: 650px) {
                //     font-size: 18px;
                // }
            }
        }
    }
}