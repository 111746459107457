.base-wrapper {
    height: 100vh;
    width: 100vw;
    // min-height: 500px;
    
    & > div {
        padding: 20px;
        height: calc(100vh - 40px);
        // min-height: 460px;

    }

    @media screen and (max-width: 1000px) {
        height: auto;

        & > div {
            height: auto;
        }

        &:first-child > div {
            height: calc(100vh - 40px);
        }
    }
}