.contacts {
    & > div {
        padding-bottom: 0;
    }
    .body {
        display: flex;
        height: calc(80vh - 20px);

        @media screen and (max-width: 550px) {
            flex-direction: column;
            align-items: center;
            padding-bottom: 20px;
            height: auto;
            & > div {
                max-width: 140px;
                margin: 0 !important;
                justify-content: center !important;
                padding-bottom: 5px;
            }
        }

        & > div {
            flex: 1;
            display: flex;
            align-items: center;

            &:first-child {
                justify-content: flex-end;
                margin-right: 50px;
            }

            &:nth-child(3) {
                justify-content: center;
            }

            &:last-child {
                margin-left: 50px;
            }

            & > img {
                max-height: 95%;
                max-width: 95%;
                cursor: zoom-in;
            }

            &.zoomed {
                position: fixed;
                left: 0;
                top: 0;
                z-index: 1000;
                margin: 0;
                height: 100vh;
                width: 100vw;
                max-width: none;
                justify-content: center;
                background-color: rgba(0,0,0,0.3);
                cursor: zoom-out;

                & > img {
                    cursor: zoom-out;
                }
            }
        }

        .crutch-plug {
            display: none;
        }

        &.zoomed.first {
            .crutch-plug.first {
                display: block;
                margin: 0 25px;
            }
        }

        &.zoomed.central {
            .crutch-plug.first {
                display: block;
            }
        }

        &.zoomed.second {
            .crutch-plug.second {
                display: block;
                margin: 0 25px;
            }
        }
    }

    .footer {
        display: flex;
        position: absolute;
        left: 0;
        right: 0;
        height: 20vh;
        min-height: 160px;
        background-color: rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 1020px) {
            justify-content: center;
        }

        .left {
            font-size: 27px;
            align-self: center;
            margin-left: 5vw;
            width: calc(100vw - 742px - 50px - 5vw);
            text-align: center;
            white-space: nowrap;
            @media screen and (max-width: 1400px) {
                white-space: break-spaces;
            }

            @media screen and (max-width: 1020px) {
                display: none;
            }
        }

        .right {
            margin-right: 50px;
            align-self: center;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media screen and (max-width: 1020px) {
                width: min-content;
                right: auto;
                margin: 25px auto;

                & > div {
                    text-align: center;
                }

                @media screen and (max-height: 1200px) {
                    margin: 0 auto;
                }
            }
            
            & > div {
                font-size: 27px;
                white-space: nowrap;

                &:last-child {
                    font-size: 20px;
                }
            }

            @media screen and (max-width: 770px) {
                & > div {
                    font-size: 20px;
                    white-space: break-spaces;
    
                    &:last-child {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}